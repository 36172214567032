import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { sessionExpired } from 'src/features/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 'FETCH_ERROR') {
    // Cors error
    toast.error(result.error.error);
  }

  if (result.error && result.error.status === 400) {
    toast.error(result.error.data.error.message);
  }

  if (result.error && result.error.status === 401) {
    // toast.error(result.error.data.error.message);

    // try to get a new token
    const refreshResult = await baseQuery('/auth/refresh', api, extraOptions);
    if (refreshResult.data) {
      // store the new token
      //   api.dispatch(tokenReceived(refreshResult.data));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(sessionExpired());
    }
  }
  return result;
};

export const commonApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['License', 'User', 'Data'],
  endpoints: () => ({}),
});
