import axios from 'axios';

export const OAUTH_URL = `https://api.envato.com/authorization?response_type=code&client_id=${process.env.REACT_APP_ENVATO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ENVATO_REDIRECT_URL}`;
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
});

api.interceptors.request.use(
  (config) => {
    if (!config.headers['Authorization']) {
      const authToken = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).auth).token;
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }

    return config;
  },
  async (error) => {
    return Promise.reject(error);
  },
);

export default api;

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
});
