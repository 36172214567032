import React, { Component, Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './scss/style.scss';

const loading = <div className="pt-3 text-center"></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/Login'));
const Terms = React.lazy(() => import('./views/pages/Terms'));
const Privacy = React.lazy(() => import('./views/pages/Privacy'));

class App extends Component {
  render() {
    return (
      <>
        <Toaster position="bottom-right" />

        <Router>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/terms" name="Terms of Service" element={<Terms />} />
              <Route exact path="/privacy" name="Privacy Policy" element={<Privacy />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </Router>
      </>
    );
  }
}

export default App;
